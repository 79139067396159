import React, { useState, useEffect } from "react";
import QRCode from "qrcode";
import jsPDF from "jspdf";
import html2canvas from "html2canvas";

import Modal from "react-modal";
import "../product.css";
import ExcelJS from "exceljs";
import logotick from "../../../../publicAssets/img/lt2.svg";
import axios from "axios";

Modal.setAppElement("#root");

const QrPreview = ({ QrCode, QrModal }) => {
  const [showModal, setShowModal] = useState(false);
  const [qrCodes, setQrCodes] = useState([]);
  const [qrCodesData, setQrCodesData] = useState([]);

  const [qrCodesId, setQrCodesId] = useState("");

  console.log(showModal);

  useEffect(() => {
    setShowModal(QrModal);
    generateQrCodes(qrCodesData);
    setQrCodesId(QrCode?.[0]?.qrid || null);
  }, [QrCode, qrCodesData]);

  const fetchData = async () => {
    try {
      const response = await axios.get(`/product/qrid/${qrCodesId}`);
      setQrCodesData(response.data); // Data yang sudah difilter dari backend
    } catch (error) {
      console.error("Error fetching data:", error.message);
    }
  };

  useEffect(() => {
    if (qrCodesId) {
      fetchData(); // Fetch data hanya jika qrCodesId memiliki nilai
    }
  }, [qrCodesId]);

  const generateQrCodes = async (qr) => {
    try {
      if (qr && qr.length > 0) {
        const qrCodesDatas = await Promise.all(
          qr.map(async (item) => {
            const qrDataURL = await generateQR(item.code);
            return qrDataURL;
          }),
        );
        setQrCodes(qrCodesDatas);
      } else {
        console.log("QrCode is empty or not initialized.");
      }
    } catch (error) {
      console.error("Error generating QR Codes:", error);
    }
  };

  const generateQR = async (text) => {
    try {
      const qrDataURL = await QRCode.toDataURL(text);

      const logoImage = new Image();
      logoImage.src = logotick;

      const qrImage = new Image();
      qrImage.src = qrDataURL;

      await Promise.all([loadImage(qrImage), loadImage(logoImage)]);

      const canvas = document.createElement("canvas");
      const ctx = canvas.getContext("2d");

      canvas.width = qrImage.width;
      canvas.height = qrImage.height;

      ctx.drawImage(qrImage, 0, 0);

      const logoSize = 20.1;
      const x = (canvas.width - logoSize) / 2;
      const y = (canvas.height - logoSize) / 2;
      ctx.drawImage(logoImage, x, y, logoSize, logoSize);

      const finalDataURL = canvas.toDataURL("image/png");
      // console.log(finalDataURL);
      return finalDataURL;
    } catch (error) {
      console.error("Error generating QR Code:", error);
      return null;
    }
  };

  const loadImage = (image) => {
    return new Promise((resolve, reject) => {
      image.onload = () => resolve();
      image.onerror = (error) => reject(error);
    });
  };

  const handleCloseModal = () => {
    setShowModal(false);
  };

  const handleDownload = () => {
    const modalContent = document.getElementById("modalContent");

    html2canvas(modalContent, {
      scale: 2,
      scrollY: -window.scrollY,
      windowWidth: document.documentElement.offsetWidth,
      windowHeight: document.documentElement.offsetHeight,
    }).then((canvas) => {
      const imgData = canvas.toDataURL("image/png");
      const pdf = new jsPDF({
        orientation: "portrait",
        unit: "in",
        format: [8.5, 13.5],
      });

      const imgWidth = 8.5;
      const imgHeight = (canvas.height * imgWidth) / canvas.width;

      pdf.addImage(imgData, "PNG", 0, 0, imgWidth, imgHeight);
      pdf.save("modal_content.pdf");
      setShowModal(false);
    });
  };

  useEffect(() => {
    if (showModal) {
      setTimeout(() => {
        handleDownload(); // Trigger the download
      }, 500); // Delay slightly to allow the modal content to render
    }
  }, [showModal]);

  return (
    <div>
      <Modal
        isOpen={showModal}
        onRequestClose={handleCloseModal}
        contentLabel="Qr Preview Modal"
        className="mPrevQr"
        style={{
          overlay: {
            backgroundColor: "rgba(0, 0, 0, 0.5)",
          },
          content: {
            width: "215.9mm",
            height: "332.9mm",
            margin: "auto",
          },
        }}
      >
        <div style={{ position: "relative" }}>
          <div
            className="d-flex justify-content-between mb-4"
            style={{ position: "absolute" }}
          >
            <button onClick={handleDownload}>Download PDF</button>
            <button onClick={handleCloseModal}>Close Modal</button>
          </div>
          <div
            id="modalContent"
            style={{
              width: "215.9mm",
              height: "332.9mm",
              padding: "50px 12px 100px 90px",
            }}
          >
            <div className="row mb-2">
              {qrCodes
                .filter((qrDataURL, index) =>
                  QrCode[index]?.code?.startsWith("S"),
                )
                .map((qrDataURL, index) => (
                  <div key={index}>
                    <div
                      className="slopQr d-flex p-3 align-items-center"
                      style={{
                        width: "100%",
                        height: "300px",
                        marginBottom: "20.5px",
                        marginLeft: "20px",
                      }}
                    >
                      <div className="slopQrText p-2"></div>
                      <div
                        className="sQrinn"
                        style={{ marginLeft: "26px", marginTop: "-40px" }}
                      >
                        <img
                          style={{ width: "180px" }}
                          src={qrDataURL}
                          alt={`QR Code ${index + 1}`}
                        />
                        <p
                          style={{
                            fontSize: "18px",
                            padding: "2px",
                            maxWidth: "100px",
                            position: "relative",
                            top: "-12px",
                          }}
                          className=" text-center textvcode mx-auto mb-0"
                        >
                          {QrCode[index]?.verifcode}
                        </p>{" "}
                      </div>
                    </div>
                  </div>
                ))}
            </div>
            <div className="row g-2">
              {qrCodes
                .map((qrDataURL, index) => ({
                  qrDataURL,
                  code: qrCodesData[index]?.code,
                  verifcode: qrCodesData[index + 1]?.verifcode,
                }))
                .filter(({ code }) => code?.startsWith("B"))
                .sort((a, b) => {
                  const numA = parseInt(a.code.substring(1, 3), 10);
                  const numB = parseInt(b.code.substring(1, 3), 10);
                  return numA - numB;
                })
                .map(({ qrDataURL, verifcode }, index) => (
                  <div
                    key={index}
                    className="col-4 d-flex justify-content-center mt-0"
                  >
                    <div
                      className="slopQr"
                      style={{
                        width: "219.31px",
                        height: "264.56px",
                        marginBottom: "10px",
                        marginLeft: "10px",
                      }}
                    >
                      <img
                        style={{ width: "140px", marginTop: "80px" }}
                        src={qrDataURL}
                        alt={`QR Code ${index + 1}`}
                        className="mx-auto d-flex"
                      />
                      <p
                        style={{
                          fontSize: "16px",
                          maxWidth: "100px",
                          position: "relative",
                          top: "-8px",
                        }}
                        className="text-center textvcode mx-auto mb-0"
                      >
                        {verifcode}
                      </p>
                    </div>
                  </div>
                ))}
            </div>

            <div className="d-flex justify-content-around">
              {/* 1*/}
              <div className="d-flex flex-column flex-wrap justify-content-between">
                {qrCodes
                  .filter((qrDataURL, index) =>
                    qrCodesData[index]?.code?.startsWith("P"),
                  )
                  .slice(0, 5)
                  .map((qrDataURL, index) => (
                    <div key={index} className="mt-0">
                      <div
                        className="slopQr d-flex flex-column justify-content-center"
                        style={{
                          width: "94.48px",
                          height: "94.48px",
                          borderRadius: "100%",
                          marginBottom: "10px",
                        }}
                      >
                        <img
                          className="mx-auto d-flex"
                          style={{ width: "55px" }}
                          src={qrDataURL}
                          alt={`QR Code ${index + 1}`}
                        />
                        <p
                          style={{
                            fontSize: "8px",
                            padding: "2px",
                            maxWidth: "40px",
                            width: "100%",
                            position: "relative",
                            top: "-4px",
                          }}
                          className=" text-center textvcode2 mx-auto mb-0"
                        >
                          {qrCodesData[index + 4]?.verifcode}
                        </p>
                      </div>
                    </div>
                  ))}
              </div>

              {/* 2 */}
              <div className="d-flex flex-column flex-wrap justify-content-between ">
                {qrCodes
                  .filter((qrDataURL, index) =>
                    qrCodesData[index]?.code?.startsWith("P"),
                  )
                  .slice(5, 10)
                  .map((qrDataURL, index) => (
                    <div key={index} className="mt-0">
                      <div
                        className="slopQr d-flex flex-column justify-content-center"
                        style={{
                          width: "94.48px",
                          height: "94.48px",
                          borderRadius: "100%",
                          marginBottom: "10px",
                        }}
                      >
                        <img
                          className="mx-auto d-flex"
                          style={{ width: "55px" }}
                          src={qrDataURL}
                          alt={`QR Code ${index + 1}`}
                        />
                        <p
                          style={{
                            fontSize: "8px",
                            padding: "2px",
                            maxWidth: "40px",
                            width: "100%",
                            position: "relative",
                            top: "-4px",
                          }}
                          className=" text-center textvcode2 mx-auto mb-0"
                        >
                          {qrCodesData[index + 9]?.verifcode}
                        </p>
                      </div>
                    </div>
                  ))}
              </div>

              {/* 3*/}
              <div className="d-flex flex-column flex-wrap justify-content-between">
                {qrCodes
                  .filter((qrDataURL, index) =>
                    qrCodesData[index]?.code?.startsWith("P"),
                  )
                  .slice(10, 15)
                  .map((qrDataURL, index) => (
                    <div key={index} className="mt-0">
                      <div
                        className="slopQr d-flex flex-column justify-content-center"
                        style={{
                          width: "94.48px",
                          height: "94.48px",
                          borderRadius: "100%",
                          marginBottom: "10px",
                        }}
                      >
                        <img
                          className="mx-auto d-flex"
                          style={{ width: "55px" }}
                          src={qrDataURL}
                          alt={`QR Code ${index + 1}`}
                        />
                        <p
                          style={{
                            fontSize: "8px",
                            padding: "2px",
                            maxWidth: "40px",
                            width: "100%",
                            position: "relative",
                            top: "-4px",
                          }}
                          className=" text-center textvcode2 mx-auto mb-0"
                        >
                          {qrCodesData[index + 14]?.verifcode}
                        </p>
                      </div>
                    </div>
                  ))}
              </div>

              {/* 4 */}
              <div className="d-flex flex-column flex-wrap justify-content-between">
                {qrCodes
                  .filter((qrDataURL, index) =>
                    qrCodesData[index]?.code?.startsWith("P"),
                  )
                  .slice(15, 20)
                  .map((qrDataURL, index) => (
                    <div key={index} className="mt-0">
                      <div
                        className="slopQr d-flex flex-column justify-content-center"
                        style={{
                          width: "94.48px",
                          height: "94.48px",
                          borderRadius: "100%",
                          marginBottom: "10px",
                        }}
                      >
                        <img
                          className="mx-auto d-flex"
                          style={{ width: "55px" }}
                          src={qrDataURL}
                          alt={`QR Code ${index + 1}`}
                        />
                        <p
                          style={{
                            fontSize: "8px",
                            padding: "2px",
                            maxWidth: "40px",
                            width: "100%",
                            position: "relative",
                            top: "-4px",
                          }}
                          className=" text-center textvcode2 mx-auto mb-0"
                        >
                          {qrCodesData[index + 19]?.verifcode}
                        </p>
                      </div>
                    </div>
                  ))}
              </div>

              {/* 5 */}
              <div className="d-flex flex-column flex-wrap justify-content-between ">
                {qrCodes
                  .filter((qrDataURL, index) =>
                    qrCodesData[index]?.code?.startsWith("P"),
                  )
                  .slice(20, 25)
                  .map((qrDataURL, index) => (
                    <div key={index} className="mt-0">
                      <div
                        className="slopQr d-flex flex-column justify-content-center"
                        style={{
                          width: "94.48px",
                          height: "94.48px",
                          borderRadius: "100%",
                          marginBottom: "10px",
                        }}
                      >
                        <img
                          className="mx-auto d-flex"
                          style={{ width: "55px" }}
                          src={qrDataURL}
                          alt={`QR Code ${index + 1}`}
                        />
                        <p
                          style={{
                            fontSize: "8px",
                            padding: "2px",
                            maxWidth: "40px",
                            width: "100%",
                            position: "relative",
                            top: "-4px",
                          }}
                          className=" text-center textvcode2 mx-auto mb-0"
                        >
                          {qrCodesData[index + 24]?.verifcode}
                        </p>
                      </div>
                    </div>
                  ))}
              </div>

              {/* 6 */}
              <div className="d-flex flex-column flex-wrap justify-content-between ps-2">
                {qrCodes
                  .filter((qrDataURL, index) =>
                    qrCodesData[index]?.code?.startsWith("P"),
                  )
                  .slice(25, 30)
                  .map((qrDataURL, index) => (
                    <div key={index} className="mt-0">
                      <div
                        className="slopQr d-flex flex-column justify-content-center"
                        style={{
                          width: "94.48px",
                          height: "94.48px",
                          borderRadius: "100%",
                          marginBottom: "10px",
                        }}
                      >
                        <img
                          className="mx-auto d-flex"
                          style={{ width: "55px" }}
                          src={qrDataURL}
                          alt={`QR Code ${index + 1}`}
                        />
                        <p
                          style={{
                            fontSize: "8px",
                            padding: "2px",
                            maxWidth: "40px",
                            width: "100%",
                            position: "relative",
                            top: "-4px",
                          }}
                          className=" text-center textvcode2 mx-auto mb-0"
                        >
                          {qrCodesData[index + 29]?.verifcode}
                        </p>
                      </div>
                    </div>
                  ))}
              </div>
            </div>
          </div>
        </div>
      </Modal>
    </div>
  );
};

export default QrPreview;
